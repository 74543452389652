@import "./shared", "node_modules/minx/src/reset",
  "node_modules/minx/src/elements", "./grid";

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap");

* {
  font-family: "AvertaRegular" !important;
  //color:#000000;
}

pre,
code span,
code {
  font-family: Consolas, monaco, monospace !important;
  font-size: 15px;
}

@font-face {
  font-family: "AvertaRegular";
  src: local("AvertaBold"),
    url("../../assets/fonts/AvertaRegular.ttf") format("truetype");
  font-weight: normal;
}
pre {
  background-color: #0e141a !important;
}

@font-face {
  font-family: "AvertaBold";
  src: local("AvertaBold"), url("../../assets/fonts/AvertaBold.ttf") format("truetype");
  font-weight: normal;
}

html {
  overflow-y: hidden;
}

.textColor {
  color: #002e6e !important;
}

body {
  position: relative;
  background: #fefcff !important;
}

a {
  color: inherit;
  text-decoration: none;
}

.no-border-drawer {
  border: 0 !important;
}

.hide {
  display: none !important;
}

.mainContainer {
  flex: 1;
  margin: 20px 0px;
  max-height: 92vh;
}

.MuiTouchRipple-root {
  opacity: 0.3;
}

::selection {
  background: rgba(200, 200, 255, 0.2);
}
main {
  width: 100%;
  //padding-top: 60px;
}
.MuiDialog-paper {
  width: 100%;
}

// Custom Paading required only for firefox
@-moz-document url-prefix() {
  .padding-for-firefox {
    padding: 45px;
  }
}

.ck.ck-content {
  height: 100%;
}

btn {
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  // border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  // border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  // border-radius: 5px;
}
